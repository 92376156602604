import React from 'react'
import { useLocation } from 'react-router-dom';


const printReceipt = (data) => {
   
    const dateTime = new Date().toLocaleString();
    alert(JSON.stringify(data.orderDetails))
    

    let receipt = `Receipt\n\n`;
    receipt += `Customer: ${data.text}\n\n`;
    receipt += `Items:\n`;

    
      receipt += `${data.orderDetails[0].dish1}:qty${data.orderDetails[0].qu1}: ₹${data.orderDetails[0].dish1price} `;
      receipt += `Amt${(data.orderDetails[0].dish1price * data.orderDetails[0].qu1)}\n`;
      receipt += `${data.orderDetails[0].dish2}:qty${data.orderDetails[0].qu2} ₹${data.orderDetails[0].dish2price}`;
      
      receipt += `Amt${(data.orderDetails[0].dish2price * data.orderDetails[0].qu2)}\n`;



      receipt += `\nSubTotal: ${data.orderDetails[0].dish1price + data.orderDetails[0].dish2price}\n\n`;
    receipt += `\nSgst: ${((data.orderDetails[0].dish1price + data.orderDetails[0].dish2price)*data.orderDetails[0].sgst)}\n\n`;
    receipt += `\nCgst: ${((data.orderDetails[0].dish1price + data.orderDetails[0].dish2price)*data.orderDetails[0].cgst)}\n\n`;
    receipt += `\nTotal (Including SGST and CGST): ${(data.orderDetails[0].dish1price + data.orderDetails[0].dish2price)+(data.orderDetails[0].dish1price + data.orderDetails[0].dish2price)*(data.orderDetails[0].sgst+data.orderDetails[0].cgst
        )}\n\n`;
     
        

 
    receipt += `Date: ${dateTime}\n`;

    const printWindow = window.open('');
    printWindow.document.write('<html><head><title>Receipt</title></head><body>');
    printWindow.document.write(`<pre>${receipt}</pre>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

export default function PrintRecipt() {
    const location=useLocation()
    const data=JSON.parse(location.state.data)
   
  return (
    <div>
    <h1>Receipt Printer</h1>
    <button onClick={printReceipt(data)}>Print Receipt</button>
  </div>

  )
}
