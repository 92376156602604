// import React, { useEffect, useState } from "react";
// import {
//   Grid,
//   InputAdornment,
//   TextField,
//   IconButton,
//   Typography,
//   Switch,
//   Avatar,
//   Divider,
//   Button,
//   Paper,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import MicIcon from "@mui/icons-material/Mic";
// import WestIcon from "@mui/icons-material/West";
// import EastIcon from "@mui/icons-material/East";
// import LocalMallIcon from '@mui/icons-material/LocalMall';
// import "../administrator/menu.css";
// import img3 from "../../assets/sg.jpg";

// import img1 from "../../assets/nv.jpg";
// import img2 from "../../assets/green.png";
// import RemoveIcon from "@mui/icons-material/Remove";
// import AddIcon from "@mui/icons-material/Add";
// import FloatingMenu from "./FloatingMenu";
// import Company from "./Company";
// import Box from "./Box";
// export default function Menu() {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [vegOnly, setVegOnly] = useState(false);
//   const [nonVegOnly, setNonVegOnly] = useState(false);
//   const [button1, setButton1] = useState("block");
//   const [button2, setButton2] = useState("none")
//   const [showViewCart, setShowViewCart] = useState(false);


//   const [value, setValue] = useState(1);
//   const [cart,setCart]=useState(Array)
    
//    const data=[
//    {
//     image:img2,
//    text:'veg Schezwan Fried Rice',
//    price:143,
//    images2:img3,
//    index:1,
//    cart:1
//    },
    
//    {
//     image:img2,
//    text:'veg Hakka Noodles & veg Manchurian Bowl',
//    price:191,
//    images2:img3,
//    index:2,
//    cart:1
//    },
//    {
//     image:img2,
//    text:'veg Hakka Noodles & veg Manchurian Bowl',
//    price:191,
//    images2:img3,
//    index:3,
//    cart:1
//    },
//    {
//     image:img2,
//    text:'veg Hakka Noodles & veg Manchurian Bowl',
//    price:191,
//    images2:img3,
//    index:4,
//    cart:1
//    },
     



//    ]

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleVegSwitchChange = () => {
//     setVegOnly(!vegOnly);
//   };

//   const handleNonVegSwitchChange = () => {
//     setNonVegOnly(!nonVegOnly);
//   };


  
//   const handleClick = (index,cartValue) => {
     
//     setCart([...cart,{index:index,cartValue:cartValue}])
//     setButton1("none");
//     setButton2("block");
//     setShowViewCart(true);
//   };

//   const handleRemove = () => {
//     if (value != 1) {
//       setValue(value - 1);
//     } else {
//       setButton1("block");
//       setButton2("none");
//     }
//   };
//   const handleAdd = (index) => {
//     var existingInCart=cart.findIndex((i)=>i.index===index)
//     cart[existingInCart].cartValue=cart[existingInCart].cartValue+1
//     alert(JSON.stringify(cart))

//     // setValue(value + 1);
//   };

//   useEffect(()=>{

//   },[cart])

// const ViewCart = () => {
// return(
// <Grid >
//   <Grid container spacing={2}>
//    <Grid item xs={12} sx={{bgcolor:'#6ab04c',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',height:50,}}>

// <Typography sx={{display:'flex',justifyContent:'center',ml:2,mb:2,color:'white',}}>1 item |&#8377;199</Typography>
//  <Typography sx={{color:'white',ml:25,mr:2,mb:2}}>ViewCart<LocalMallIcon sx={{width:20,ml:1,}}/></Typography>

// </Grid>
// </Grid>
// </Grid>

//    )}
//   const CardComponent = () => {
//     return (
//       <Paper sx={{ width: 450, backgroundColor: 'white' }}>
//         <Grid container spacing={2} sx={{ width: 450, backgroundColor: 'white' }}>
//           {data.map((item,index) => (
//             <React.Fragment key={item.text}>
//               <Grid item xs={7}>
//                 <img src={item.image} alt="Masala Grill" width={30} />
//                 <Typography style={{ fontSize: '22px', fontWeight: 'bold' }}>{item.text}</Typography>
//                 <Typography style={{ fontSize: '20px' }}>&#8377;{item.price}</Typography>
//                 <Typography style={{ color: 'green', fontSize: '18px', fontWeight: 'bold' }}> *4.0 (52)</Typography>
//                 <Typography>Serve 1 | position Size grams | This ones for all...more</Typography>
//               </Grid>
//               <Grid item xs={5}>
//                 <img src={item.images2} alt="Masala Grill" width={150} />
//                 <Grid sx={{ display:button1 }}>
//                   <Button
//                     onClick={() => handleClick(index,item.cart)}
//                     variant="outlined"
//                     size="large"
//                     sx={{ width: 150, color: 'green' }}
//                   >
//                     ADD
//                   </Button>
//                 </Grid>
//                 <Grid sx={{ display: button2 }}>
//                   <Grid
//                     item
//                     xs={12}
//                     sx={{ display: 'flex', fontSize: 30, justifyContent: 'center' }}
//                   >
//                     <Grid>
//                       <IconButton
//                         sx={{
//                           color: '#001E3C',
//                           bgcolor: 'white',
//                           m: 1,
//                           '&:hover': { bgcolor: 'white' },
//                         }}
//                         onClick={() => handleRemove()}
//                       >
//                         <RemoveIcon />
//                       </IconButton>
//                     </Grid>
//                     <Grid
//                       sx={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         color: '#000',
//                       }}
//                     >
//                       {
//                       cart!=[]?item.cart:cart.map((item)=>(
//                         item.cartValue
//                        )) 
//                       }
//                       {console.log(cart)}
//                     </Grid>
//                     <Grid>
//                       <IconButton
//                         sx={{
//                           color: '#001E3C',
//                           bgcolor: 'white',
//                           m: 1,
//                           '&:hover': { bgcolor: 'white' },
//                         }}
//                         onClick={() => handleAdd(index)}
//                       >
//                         <AddIcon />
//                       </IconButton>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </React.Fragment>
//           ))}
//           <Grid item xs={12} sx={{display:'flex',justifyContent:'center',position:'fixed',left:650,bottom:50}}>
//         <FloatingMenu />
//       </Grid>
//         </Grid>
//         <Divider
//           sx={{
//             backgroundColor: 'black',
//             height: '1px',
//             width: '100%',
//             mt: 2,
//           }}
//         />
//         <Grid item xs={12} sx={{display:'flex',justifyContent:'center',position:'fixed',left:500,bottom:-5,}}>
//          {showViewCart && <ViewCart />}
//          </Grid>

//       </Paper>
//     );
//   }; 

//   return (
//     <Grid container spacing={2}>
      
     
    
//       <Grid item xs={12}>
//       <Box/>
//       </Grid>
//             <Grid item xs={12}>
//         <Typography sx={{ display: "flex", justifyContent: "center" }}>
//           <WestIcon sx={{ marginRight: 1 }} /> Menu{" "}
//           <EastIcon sx={{ marginLeft: 1 }} />
//         </Typography>
//       </Grid>
//       <Grid
//         item
//         xs={12}
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           marginTop: "10px",
//           justifyContent: "center",
//         }}
//       >
//         <TextField
//           placeholder="Search dishes"
//           value={searchQuery}
//           onChange={handleSearchChange}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton
//                   sx={{
//                     color: "black",
//                     padding: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <SearchIcon />
//                 </IconButton>
//                 <IconButton
//                   sx={{
//                     color: "black",
//                     padding: "5px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <MicIcon />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           sx={{
//             width: "300px", // Increase the width as needed
//             padding: "10px", // Increase the padding as needed
//           }}
//         />
//       </Grid>

//       <Grid
//         item
//         xs={12}
//         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
//       >
//         <Grid
//           sx={{
//             marginLeft: "15px",
//             color: "grey",
//             border: 1,
//             borderStyle: "solid",
//             borderRadius: 10,
//             paddingLeft: 1,
//             paddingRight: 1,
//           }}
//         >
//           <Switch
//             checked={vegOnly}
//             onChange={handleVegSwitchChange}
//             checkedIcon={
//               <Avatar alt="Veg" src={img2} sx={{ width: 20, height: 20 }} />
//             }
//             icon={
//               <Avatar alt="Non-Veg" src={img2} sx={{ width: 20, height: 20 }} />
//             }
//           />
//         </Grid>
//         <Grid
//           sx={{
//             marginLeft: "15px",
//             color: "grey",
//             border: 1,
//             borderStyle: "solid",
//             borderRadius: 10,
//             paddingLeft: 1,
//             paddingRight: 1,
//           }}
//         >
//           <Switch
//             checked={nonVegOnly}
//             onChange={handleNonVegSwitchChange}
//             icon={
//               <Avatar alt="Veg" src={img1} sx={{ width: 20, height: 20 }} />
//             }
//             checkedIcon={
//               <Avatar alt="Non-Veg" src={img1} sx={{ width: 20, height: 20 }} />
//             }
//           />
//         </Grid>
//         <Grid
//           sx={{
//             marginLeft: "15px",
//             color: "grey",
//             border: 1,
//             borderStyle: "solid",
//             borderRadius: 10,
//             paddingLeft: 1,
//             paddingRight: 1,
//             width: 150,
//             height: 40,
//           }}
//         >
//           <Typography sx={{ textAlign: "center", padding: 1, color: "black" }}>
//             Ratings 4.0+
//           </Typography>
//         </Grid>
//         <Grid
//           sx={{
//             marginLeft: "15px",
//             color: "grey",
//             border: 1,
//             borderStyle: "solid",
//             borderRadius: 10,
//             paddingLeft: 1,
//             paddingRight: 1,
//             width: 150,
//             height: 40,
//           }}
//         >
//           <Typography
//             sx={{
//               textAlign: "center",
//               padding: 1,
//               color: "black",
//             }}
//           >
//             BestSeller
//           </Typography>
//         </Grid>
//       </Grid>

//       <Divider
//         sx={{
//           backgroundColor: "black",
//           height: "1px",
//           width: "100%",
//           mt:2
//         }}
//       />
//       <Grid item xs={9}>
//         <Typography sx={{ fontFamily: "poppins", fontSize: 30 }}>
//           Recommended (12)
//         </Typography>
//       </Grid>

//       <Grid item xs={12} sx={{display:'flex',justifyContent:'center',overflowY:'scroll',scrollbarWidth:'none'}}>
//         <CardComponent />
//       </Grid>
      
       
      
      
//     </Grid>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
  Switch,
  Avatar,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MicIcon from "@mui/icons-material/Mic";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import FloatingMenu from "./FloatingMenu";


// Import your images here
import img3 from "../../assets/sg.jpg";
import img1 from "../../assets/nv.jpg";
import img2 from "../../assets/green.png";
import Box from "./Box";

export default function Menu() {
  const [searchQuery, setSearchQuery] = useState("");
  const [vegOnly, setVegOnly] = useState(false);
  const [nonVegOnly, setNonVegOnly] = useState(false);
  const [button1, setButton1] = useState("block");
  const [button2, setButton2] = useState("none");
  const [showViewCart, setShowViewCart] = useState(false);
  // const [value, setValue] = useState(1);
  const [cart, setCart] = useState(Array(0).fill(null));
  const [selectedProducts, setSelectedProducts] = useState([]);
  

  const data = [
    {
      image: img2,
      text: 'veg Schezwan Fried Rice',
      price: 143,
      images2: img3,
      index: 0,
      cart: 1
    },
    {
      image: img2,
      text: 'veg Hakka Noodles & veg Manchurian Bowl',
      price: 191,
      images2: img3,
      index: 1,
      cart: 1
    },
    {
      image: img2,
      text: 'veg Hakka Noodles & veg Manchurian Bowl',
      price: 191,
      images2: img3,
      index: 2,
      cart: 1
    },
    {
      image: img2,
      text: 'veg Hakka Noodles & veg Manchurian Bowl',
      price: 191,
      images2: img3,
      index: 3,
      cart: 1
    },
  ];

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleVegSwitchChange = () => {
    setVegOnly(!vegOnly);
  };

  const handleNonVegSwitchChange = () => {
    setNonVegOnly(!nonVegOnly);
  };

  const handleClick = (index, cartValue) => {
    // alert(index,cartValue)
  const data={
    index:index,
    cart:cartValue,
  }
  
  setCart([...cart,...Array(1).fill(data)])

    // setCart([...cart,])
    setSelectedProducts((prevSelected) => [...prevSelected, index]);
    setButton1("none");
    setButton2("block");
    setShowViewCart(true);
  };
  
  const handleRemove = (index) => {
    // let data=[...cart]
    // data[index]
    let data=cart.map((item)=>{
      if(item.index===index){
        if(item.cart==1){
    setShowViewCart(false);
          
          setCart((prevSelected) =>
    prevSelected.filter((item) => item.index !== index)
  );
          setSelectedProducts((prevSelected) =>
    prevSelected.filter((item) => item !== index)
  );
        }else{
          return {...item,cart:item.cart-1};
        }
       
      }
  
      return item;
     })
    setCart(data)
  };
  
  
  const handleAdd = (index) => {
   let data=cart.map((item)=>{
    if(item.index===index){
      return {...item,cart:item.cart+1};
    }

    return item;
   })
   setCart(data)
  };
  
  
  useEffect(() => {}, [cart,selectedProducts]);

  const ViewCart = () => {
    const total = selectedProducts.reduce((acc, index) => {
      // Find the corresponding product in the 'data' array
      const product = data.find((item) => item.index === index);
    
      // Find the corresponding cart item for the product
      const cartItem = cart.find((item) => item.index === index);
    
      // Add the total price (price * quantity) of the product to the total
      return acc + (product ? product.price * (cartItem ? cartItem.cart : 0) : 0);
    }, 0);
    
    return (
      <Grid>
        <Grid container spacing={2}>
       
         
          <React.Fragment >
          <Grid item xs={12} sx={{ bgcolor: '#6ab04c', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 55, }}>
            <Typography sx={{ display: 'flex', justifyContent: 'center', ml: 2, mb: 2, color: 'white', }}>{selectedProducts.length} item | &#8377;{total}</Typography>
            <Typography sx={{ color: 'white', ml: 25, mr: 2, mb: 2 }}>ViewCart<LocalMallIcon sx={{ width: 20, ml: 1, }} /></Typography>
          </Grid>
          </React.Fragment>
         
        </Grid>
         

      </Grid>
    );
  };

  const CardComponent = () => {
    return (
      <Paper sx={{ width: 380, backgroundColor: 'white',paddingBottom:15}}>
        <Grid container spacing={2} sx={{ width: 370, backgroundColor: 'white' }}>
          {data.map((item, index) => (
            <React.Fragment key={item.text}>
              <Grid item xs={7}>
                <img src={item.image} alt="Masala Grill" width={30} />
                <Typography style={{ fontSize: '22px', fontWeight: 'bold' }}>{item.text}</Typography>
                <Typography style={{ fontSize: '20px' }}>&#8377;{item.price}</Typography>
                <Typography style={{ color: 'green', fontSize: '18px', fontWeight: 'bold' }}> *4.0 (52)</Typography>
                <Typography>Serve 1 | position Size grams | This ones for all...more</Typography>
              </Grid>
              <Grid item xs={5}>
                <img src={item.images2} alt="Masala Grill" width={160} />
                <Grid sx={{ display: selectedProducts.includes(index) ? 'block' : 'none' }}>
                  <Grid item xs={12} sx={{ display: 'flex', fontSize: 30, justifyContent: 'center' }}>
                    <Grid>
                      <IconButton
                        sx={{
                          color: '#001E3C',
                          bgcolor: 'white',
                          m: 1,
                          '&:hover': { bgcolor: 'white' },
                        }}
                        onClick={() => handleRemove(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#000',
                      }}
                    >
                      {
    cart == [] ? item.cart : cart.find((item) => item.index === index)?.cart || 1
  }
                      {console.log(cart)}
                    </Grid>
                    <Grid>
                      <IconButton
                        sx={{
                          color: '#001E3C',
                          bgcolor: 'white',
                          m: 1,
                          '&:hover': { bgcolor: 'white' },
                        }}
                        onClick={() => handleAdd(index)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ display: !selectedProducts.includes(index) ? 'block' : 'none' }}>
  <Button
    onClick={() => handleClick(index, item.cart)}
    variant="outlined"
    size="large"
    sx={{ width: 150, color: 'green', borderColor: '#ffde00' }}
  >
    ADD
  </Button>
</Grid>

              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', position: 'fixed', left: 100, bottom: 55 }}>
            <FloatingMenu />
          </Grid>
        </Grid>
       
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', position: 'fixed',  bottom: -5, }}>
          {showViewCart && <ViewCart />}
        </Grid>
      </Paper>
    );
  };

  return (
    
    <Grid container spacing={2} sx={{width:400}}>
    
     <Box/>
     
     
      <Grid item xs={12}>
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          <WestIcon sx={{ marginRight: 1 }} /> Menu{" "}
          <EastIcon sx={{ marginLeft: 1 }} />
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        <TextField
          placeholder="Search dishes"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    color: "black",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  sx={{
                    color: "black",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  <MicIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            width: "300px",
            padding: "10px",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          sx={{
            marginLeft: "15px",
            color: "grey",
            border: 1,
            borderStyle: "solid",
            borderRadius: 10,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Switch
            checked={vegOnly}
            onChange={handleVegSwitchChange}
            checkedIcon={
              <Avatar alt="Veg" src={img2} sx={{ width: 20, height: 20 }} />
            }
            icon={
              <Avatar alt="Non-Veg" src={img2} sx={{ width: 20, height: 20 }} />
            }
          />
        </Grid>
        <Grid
          sx={{
            marginLeft: "15px",
            color: "grey",
            border: 1,
            borderStyle: "solid",
            borderRadius: 10,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Switch
            checked={nonVegOnly}
            onChange={handleNonVegSwitchChange}
            icon={
              <Avatar alt="Veg" src={img1} sx={{ width: 20, height: 20 }} />
            }
            checkedIcon={
              <Avatar alt="Non-Veg" src={img1} sx={{ width: 20, height: 20 }} />
            }
          />
        </Grid>
        <Grid
          sx={{
            marginLeft: "15px",
            color: "grey",
            border: 1,
            borderStyle: "solid",
            borderRadius: 10,
            paddingLeft: 1,
            paddingRight: 1,
            width: 150,
            height: 40,
          }}
        >
          <Typography sx={{ textAlign: "center", padding: 1, color: "black",width:100 }}>
            Ratings4.0+
          </Typography>
        </Grid>
        <Grid
          sx={{
            marginLeft: "15px",
            color: "grey",
            border: 1,
            borderStyle: "solid",
            borderRadius: 10,
            paddingLeft: 1,
            paddingRight: 1,
            width: 150,
            height: 40,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              padding: 1,
              color: "black",
            }}
          >
            BestSeller
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: "black",
          height: "1px",
          width: "100%",
          mt: 2
        }}
      />
      <Grid item xs={9}>
        <Typography sx={{ fontFamily: "poppins", fontSize: 30 }}>
          Recommended (12)
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', scrollbarWidth: 'none' }}>
        <CardComponent />
      </Grid>
    </Grid>
  );
}

