import Company from "./components/administrator/Company";
 import Box from "./components/administrator/Box";
 import  {BrowserRouter as Router , Routes, Route, HashRouter } from "react-router-dom";
 import Menu from "./components/administrator/Menu";
import Button from "./components/administrator/Button ";
import FloatingMenu from "./components/administrator/FloatingMenu";
import DashBoard from "./components/adminpannel/DashBoard";
import AddMenu from "./components/adminpannel/AddMenu";
import ViewOrder from "./components/adminpannel/ViewOrder";
import OrderDetail from "./components/adminpannel/OrderDetail";
import UploadMenuCsv from "./components/adminpannel/UploadMenuCsv";
import PrintRecipt from "./components/adminpannel/PrintRecipt";

function App() {
  return (
    <HashRouter>
      <Routes>
    
    <Route path="/" element={<Company/>}/>
    <Route path="/box" element={<Box/>}/>
    <Route path="/menu" element={<Menu/>}/>
    <Route path="/dashboard" element={<DashBoard/>}/>
    <Route path="/addmenu" element={<AddMenu/>}/>
    <Route path="/vieworder" element={<ViewOrder/>}/>
    <Route path="/orderdetail" element={<OrderDetail/>}/>
    <Route path="/button" element={<Button/>}/>
    <Route path="/printrecipt" element={<PrintRecipt/>}/>

    <Route path="/floatingmenu" element={<FloatingMenu/>}/>
    <Route path="/uploadmenucsv" element={<UploadMenuCsv/>}/>


     
     

    

     
    
  
    </Routes>
    </HashRouter>
  );
}

export default App;
