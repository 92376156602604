import { Grid,Typography,Button,Divider } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import VerifiedIcon from "@mui/icons-material/Verified";
import img1 from "../../assets/dch logooo.png";

import React from 'react'
import { useNavigate } from "react-router-dom";

import { useLocation } from 'react-router-dom';

export default function OrderDetail() {
  var navigate=useNavigate()
    const location =useLocation()

    const fullData=JSON.parse(location.state.data)
    const data=fullData.orderDetails[0]
        // alert(JSON.stringify(fullData.orderDetail))
  return (
    <Grid sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Grid container spacing={2} sx={{width:500}}>
       
     <Grid item xs={6} sx={{display:'flex',flexDirection:'row',}}>
     <img src={img1} alt="Masala Grill" width={120} />
       

</Grid>
<Grid item xs={6} sx={{}}>
<Button variant="outlined" sx={{bgcolor:'yellow',mt:2}}><WhatsAppIcon/>Live support</Button>
</Grid>
<Divider
    sx={{
      backgroundColor: 'black',
      height: '1px',
      width: '100%',
      
    }}
  />
  
  <Grid item xs={12}>
<Typography sx={{fontSize:28,textAlign:'left'}}>Order Details</Typography>
  </Grid>

<Grid item xs={6}>
    
    <Typography> 1 {data.dq} - {data.dish1} x {data.qu1}</Typography>
    <Typography> 2 {data.d2q} - {data.dish2} x {data.qu2}</Typography>

</Grid>
<Grid item xs={5}>
<Typography>RS: {data.dish1price}</Typography>
<Typography>RS: {data.dish2price}</Typography>
<Typography>Total: ₹ {data.dish1price + data.dish2price}</Typography>
<Typography>SGST: ₹ {((data.dish1price +data.dish2price )* data.sgst)}</Typography>
<Typography>CGST: ₹ {(data.dish1price + data.dish2price)*data.cgst}</Typography>
<Typography>Total : ₹ {(data.dish1price + data.dish2price) + ((data.dish1price + data.dish2price) * (data.sgst + data.cgst))}</Typography>


</Grid>
<Grid item xs={12}>
<Typography sx={{display:'flex'}}><LocalPhoneIcon />{data.no}</Typography>
</Grid>
 <Grid item xs={12}>
<Typography sx={{textAlign:'left'}}>Table Serve #0</Typography>
 </Grid>

 <Grid item xs={12}>
                  <Typography
                    sx={{ display: "flex", textAlign: "end",bgcolor:'blue',textAlign:'left' }}
                  >
                    {data.status === "Served" ? (
                      <VerifiedIcon />
                    ) : (
                      <AccessTimeFilledIcon />
                    )}
                    {data.status}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
               <Typography sx={{textAlign:'left',fontSize:23}}>Print Invoice Now.</Typography>
               <Button
            variant="outlined"
            color="inherit"
            onClick={()=>navigate('/PrintRecipt',{state:{data:JSON.stringify(fullData)}})}
          >
            Print Invoice
          </Button>
                </Grid>









     </Grid>






    </Grid>
  )
}
