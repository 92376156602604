import { Grid, Typography, Divider, Button, Paper } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import VerifiedIcon from "@mui/icons-material/Verified";
import React, { useState } from "react";
import img1 from "../../assets/dch logooo.png";

import { useNavigate } from "react-router-dom";
let data = [
    {
      no: 1,
      id: "Et-0210091014774",
      text: "Rahul *******4487",
      price: 199,
      status: "pending",
      orderDetails:[{
        dq:'h',
        d2q:'h',
        dish1:'veg Maharaja',
        dish2:'butter chicken',
        qu1:1,
        qu2:2,
        dish1price:299,
        dish2price :499,
        sgst:0.06,
        cgst:0.06,
        no:9034364487,
      status: "pending",

    },

      ]
    },
    {
      no: 2,
      id: "Et-3009120901863",
      text: "tg *****4487",
      price: 798,
      status: "pending",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'red sauce paasta',
        dish2:'butter panner masala',   
        qu1:1,
        qu2:1,
        dish1price:399,
        dish2price:299,
        sgst:0.06,
        cgst:0.06,
        no:9034364487,
      status: "pending",


    },

      ]
    },
    {
      no: 3,
      id: "Et-1809090918343",
      text: "jay *****6151",
      table: 3,
      price: 924,
      status: "Served",
       orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg momos',
        dish2:'panner rools',
        qu1:1,
        qu2:1,
        dish1price:199,
        dish2price:199,
        sgst:0.06,
        cgst:0.06,
        no:9074664487,
      status: "Served",
        
    },

      ]

    },
    {
      no: 4,
      id: "Et-2508120850799",
      text: "bascoji *****5577",
      table: 1,
      price: 897,
      status: "Served",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg Maharaja burger',
        dish2:'butter chap',
        qu1:1,
        qu2:1,
        dish1price:299,
        dish2price:499,
        sgst:0.06,
        cgst:0.06,
        no:9934664487,
      status: "Served",


    },

      ]
    },
    {
      no: 5,
      id: "Et-2508120887654",
      text: "harshit *****5578",
      table: 5,
      price: 1050,
      status: "pending",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg soup',
        dish2:' chicken soup',
        qu1:1,
        qu2:1,
        dish1price:99,
        dish2price:199,
        sgst:0.06,
        cgst:0.06,
        no:9634664487,
      status: "pending",


    },

      ]
    },

    {
      no: 6,
      id: "Et-250765450799",
      text: "monthan *****5897",
      table: 2,
      price: 550,
      status: "pending",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg pulao',
        dish2:'amritsarinan',
        qu1:1,
        qu2:1,
        dish1price:299,
        dish2price:299,
        sgst:0.06,
        cgst:0.06,
        no:9034664387,
      status: "pending",


    },

      ]
    },

    {
      no: 7,
      id: "Et-3256120850799",
      text: "harsh *****5877",
      table: 4,
      price: 890,
      status: "Served",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg Maharaja',
        dish2:'butter chicken',
        qu1:1,
        qu2:1,
        dish1price:299,
        dish2price:499,
        sgst:0.06,
        cgst:0.06,
        no:9034964487,
      status: "Served",


    },

      ]
    },

    {
      no: 8,
      id: "Et-2508120850300",
      text: "akshat *****5577",
      table: 8,
      price: 670,
      status: "pending",
      orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg Maharaja',
        dish2:'butter chicken',
        qu1:1,
        qu2:1,
        dish1price:299,
        dish2price:499,
        sgst:0.06,
        cgst:0.06,
        no:9038664487,
      status: "pending",


    },

      ]
    },

    {
      no: 9,
      id: "Et-2506620850300",
      text: "yash *****59000",
      table: 9,
      price: 3000,
      status: "Served",
       orderDetails:[{
        dq:'F',
        d2q:'h',
        dish1:'veg Maharaja',
        dish2:'veg mutton chap',
        qu1:1,
        qu2:1,
        dish1price:249,
        dish2price:499,
        sgst:0.06,
        cgst:0.06,
        no:7034664487,
      status: "Served",


    },

      ]

      
    },
  ];
export default function ViewOrder() {
  const [showCard, setShowCard] = useState(false);
  const [showData, setShowData] = useState(data);
  const navigate=useNavigate()
  
  const handleClick = () => {
    if(showCard!=true){
        setShowCard(true)
    }
    setShowData(data)
  };
  const handleShow = () => {
    let data1=data.filter(item=>{
        return item.status=="pending"
    })
    setShowData(data1)
    if(showCard!=true){
        setShowCard(true)
    }
  };


  const Card = () => {
    return (
      <Grid container spacing={2}>
        {showData.map((item) => (
          <Grid item xs={12} sx={{ m: 1, cursor: "pointer", }} onClick={()=>navigate('/orderdetail',{state:{data:JSON.stringify(item)}})}>
            <Paper
              sx={{
                width: 340,
                backgroundColor: item.status === "Served" ? "white" : "red",
              }}
            >
              <Grid container spacing={2} sx={{}}>
                <Grid item xs={2}>
                  <Typography>{item.no}</Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    
                  }}
                >
                  <Typography sx={{}}>{item.id}</Typography>
                  <Typography>{item.text}</Typography>
                  <Typography>RS:{item.orderDetails[0].dish1price+item.orderDetails[0].dish2price}</Typography>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ height: "25px", width: "150px", m: 1 }}
                  >
                    Table Serve
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Typography
                    sx={{ display: "flex", textAlign: "end" }}
                  >
                    {item.status === "Served" ? (
                      <VerifiedIcon />
                    ) : (
                      <AccessTimeFilledIcon />
                    )}
                    {item.status}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Grid
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid container spacing={2} sx={{ width: 400,ml:{xs:1,md:0} }}>
        <Grid item xs={6} sx={{ display: "flex", flexDirection: "row" }}>
     <img src={img1} alt="Masala Grill" width={120} />
          
        </Grid>
        <Grid item xs={6} sx={{}}>
          <Button variant="outlined" sx={{ bgcolor: "yellow", mt:0.5 }}>
            <WhatsAppIcon />
            Live support
          </Button>
        </Grid>
        <Divider
          sx={{
            backgroundColor: "black",
            height: "1px",
            width: "100%",
          }}
        />

        <Grid item xs={6}>
          <Typography sx={{fontSize: 20 }}>
            Orders !
          </Typography>
          <Typography sx={{ fontSize: 10 }}>
            2023-11-23
          </Typography>
          <Button
            onClick={() => handleClick()}
            variant="outlined"
            color="inherit"
          >
            All Orders
          </Button>
          <Typography sx={{ mt: 2 }}>
            You do not have any order.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => handleShow()}
            sx={{ mt: 5 }}
            variant="outlined"
            color="inherit"
          >
            Pending
          </Button>
        </Grid>

        <Grid item xs={12}>
          {showCard && <Card />}
        </Grid>
      </Grid>
    </Grid>
  );
}
